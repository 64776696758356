@import "../../assets/scss/variables.scss";

.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 60px;
  display: block;
  height: 30px;
  border-radius: 20px;
  background: $color-white;
  border: 1px solid $color-primary;
  cursor: pointer;
  input {
    visibility: hidden;
  }
  &-inner {
    &::before {
      content: "";
      position: absolute;
      left: 3px;
      top: 1.5px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: $color-primary-light;
      transition: 0.4s all;
    }
  }

  input:checked + &-inner:before {
    transform: translateX(27px);
    background: $color-primary;
  }
}
