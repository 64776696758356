@import "../scss/variables.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
img {
  height: auto;
  display: block;
  max-width: 100%;
}
:focus {
  outline: none;
}
